
body {
  margin: 0;
}

/* inspired by: https://css-tricks.com/responsive-data-tables/ */

.responsiveTable td .tdBefore {
  display: none;
}

@media (max-width: 40em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none; for accessibility)
  */
  
  .responsiveTable .category td .tdBefore{
    display: none;
  }
  
  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
    white-space: nowrap;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .responsiveTable tbody tr {
    padding: 0.25em;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */

    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    border-bottom: 1px solid lightgrey !important;
  }


  
  .responsiveTable .category td{
    display: flex;
    justify-content: center;
    padding-inline: 0 !important;
    font-size: 0.9em;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }
}
